@import '~crazy-ui-next/src/scss/common/z-index';

.inputWrapper {
  display: flex;
  gap: 12px;

  .dropdown {
    width: 100px;
    margin-bottom: unset;

    &[data-open='true'] {
      z-index: $z-index-popover;
    }
  }

  .version {
    margin-bottom: unset;

    div {
      width: 88px;
      margin-right: 12px;
    }
  }

  .multiWrapper {
    display: flex;
    align-items: center;
  }
}
